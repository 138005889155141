/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { Redirect } from "react-router-dom";
import "./backlog.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ReactLoading from "react-loading";
import { toast, ToastContainer } from "react-toastify";
import Pagination from "../../SubComponents/Pagination/Pagination";
import {
  deleteQuestionFunction,
  getBacklogFunction,
  getBacklogCountFunction,
} from "../../../api/questions.js";
import "./backlog.css";
import { REWRITE_URLS } from "../../config/Api";
import configuration from "../../config/Config";
import { Player } from "@lottiefiles/react-lottie-player";
var iniFilePath = configuration.digitalOceanSpaces;
var linkprv;

function Backlog() {
  const [link, setLink] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [questionData, setQuestionData] = useState([]);
  const [startUpLoad, setStartUpLoad] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [offSet, setOffSet] = useState(0);
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const initialRender = useRef(true);
  // const [noData, setNoData] = useState(false);
  const userId = window.localStorage.getItem("userId");
  console.log("questionData", questionData);

  useEffect(() => {
    if (initialRender.current === true) {
      initialRender.current = false;
    } else {
      async function handlePagination() {
        console.log("offSet", offSet);
        try {
          const getBacklogs = await getBacklogFunction(userId, offSet);
          setQuestionData(getBacklogs?.data?.data);
          setStartUpLoad(false);
        } catch (err) {
          if (err?.response?.data) {
            console.log("err.response.data.message", err.response.data.message);
            toast.error(err.response.data.message);
          } else {
            toast.error("Server Error");
          }
          setStartUpLoad(false);
        }
      }
      handlePagination();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offSet]);
  const getBacklogCount = async () => {
    try {
      const getNumberOfQuestions = await getBacklogCountFunction(userId);
      console.log(
        "count of questions",
        getNumberOfQuestions.data.data[0].count
      );
      if (+getNumberOfQuestions.data.QuestionTotalCounts !== 0) {
        const getBacklogs = await getBacklogFunction(userId, offSet);
        setNumberOfQuestions(getNumberOfQuestions.data.data[0].count);
        setQuestionData(getBacklogs.data.data);
        setStartUpLoad(false);
      }
      if (+getNumberOfQuestions.data.QuestionTotalCounts === 0) {
        setNumberOfQuestions(0);
        setStartUpLoad(false);
      }
    } catch (err) {
      if (err?.response?.data) {
        console.log("err.response.data.message", err.response.data.message);
        toast.error(err.response.data.message);
      } else {
        toast.error("Server Error");
      }
      setStartUpLoad(false);
    }
  };

  useEffect(() => {
    getBacklogCount();
  }, []);

  function handleClick(selLink, questionId) {
    console.log("selLink", selLink);
    setLink(
      REWRITE_URLS === "1"
        ? configuration.digitalOceanSpacesRewrite + selLink
        : iniFilePath + selLink
    );
    linkprv = iniFilePath + selLink;
    window.localStorage.setItem("questionId", questionId);
  }

  async function handleDelete(selQuestion) {
    console.log("selQuestion", selQuestion);
    setStartUpLoad(true);
    try {
      const deleteQuestionData = await deleteQuestionFunction(selQuestion);
      console.log("deleteQuestionData", deleteQuestionData);
      toast.success("Successfully deleted the question");
      setStartUpLoad(false);
    } catch (error) {
      if (error?.response?.data) {
        console.log("error.response.data.message", error.response.data.message);
        toast.error(error.response.data.message);
      } else {
        toast.error("Server Error");
      }
      setStartUpLoad(false);
    }
    window.location.reload(false);
  }

  function handleAddDetails(selLink, questionId, migrateQuestionId) {
    console.log(
      "selLink, questionId, migrateQuestionId",
      selLink,
      questionId,
      migrateQuestionId
    );
    linkprv = iniFilePath + selLink;
    window.localStorage.setItem("questionId", questionId);
    window.localStorage.setItem("migrateQuestionId", migrateQuestionId);
    window.localStorage.setItem("previousPath", "backlog");
    window.localStorage.setItem("previousImageLink", linkprv);
    setRedirect(true);
  }

  if (redirect) {
    return <Redirect to="/admin/add-details" />;
  }

  function paginationClick(number, offSetNumber) {
    if (Math.ceil(numberOfQuestions / 10) > 1) {
      setPageNumber(+number);
      setOffSet(+offSetNumber);
      setStartUpLoad(true);
    }
  }

  return (
    <div>
      {startUpLoad ? (
        <div className="loadingBackground">
          <ReactLoading type="spin" color="#68dff0" className="startLoadSpin" />
        </div>
      ) : null}
      <div className="queContainer">
        <div>
          <h3 className="queH3">
            {" "}
            <ArrowForwardIosIcon className="queH3Arrow" />
            Backlogs
          </h3>
        </div>
        <div className="queSubContainerBacklog">
          <div className="tableContainer">
            <table className="backlogTable">
              <thead>
                <tr>
                  <th className="questionId">Question Id</th>
                  <th>Image</th>
                  <th>Add Details</th>
                  <th className="backlogDeleteTh">Delete</th>
                  <th className="backlogRejectTh">Reject Reason</th>
                </tr>
              </thead>
              <tbody>
                {questionData.map((row, rowKey) => {
                  return (
                    <tr
                      key={rowKey}
                      onClick={(e) =>
                        handleClick(
                          row.question_file_path,
                          row.question_file_id
                        )
                      }
                    >
                      <td>{row.question_file_id}</td>
                      <td>
                        {row.question_file_path?.endsWith(".json") ? (
                          <Player
                            autoplay
                            loop
                            src={iniFilePath + row?.question_file_path}
                            style={{ width: 200, height: 200 }}
                          />
                        ) : row.question_file_path?.endsWith(".webm") ? (
                          <video
                            controls
                            name="media"
                            style={{ width: "auto", height: "42px" }}
                          >
                            <source
                              src={
                                REWRITE_URLS === "1"
                                  ? configuration.digitalOceanSpacesRewrite +
                                    row.question_file_path
                                  : iniFilePath + row.question_file_path
                              }
                              type="video/webm"
                            />
                          </video>
                        ) : (
                          <img
                            src={
                              REWRITE_URLS === "1"
                                ? configuration.digitalOceanSpacesRewrite +
                                  row.question_file_path
                                : iniFilePath + row.question_file_path
                            }
                            alt="UpLoaded"
                            className="backlogImage"
                          />
                        )}
                      </td>
                      <td>
                        <button
                          className="backlogTableButton"
                          onClick={(e) =>
                            handleAddDetails(
                              row.question_file_path,
                              row.question_file_id,
                              row.migrate_question_id
                            )
                          }
                        >
                          Add Details
                        </button>
                      </td>
                      <td>
                        <button
                          className="backlogDeleteBtn"
                          onClick={(e) => handleDelete(row.question_file_id)}
                          type="button"
                        >
                          Delete
                        </button>
                      </td>
                      <td>{row.reject_reason ?? "NA"}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <Pagination
            paginationClick={paginationClick}
            numberOfRows={numberOfQuestions}
            offSet={offSet}
            pageNumber={pageNumber}
          />
          {link !== "" ? (
            <div className="backlogImageContainer">
              <img src={link} alt="UpLoaded" className="backlogBigImage" />
            </div>
          ) : null}
        </div>
      </div>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Backlog;
