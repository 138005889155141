import React, { useState, useEffect } from "react";
import Dropdown from "../../SubComponents/Dropdown/Dropdown";
import DropdownQSet from "../../SubComponents/DropdownQSet/DropdownQSet";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { BASE_URL } from "../../config/Api";
import Axios from "axios";
import ReactLoading from "react-loading";
import { ToastContainer, toast } from "react-toastify";
// import Pagination from "../../SubComponents/Pagination/Pagination";
import PublicIcon from "@mui/icons-material/Public";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PersonIcon from "@mui/icons-material/Person";
import "./qsetCreator.css";
import {
  TopicTable,
  SubTopicTable,
  DifficultyLevel,
  Objective,
  Marks,
} from "../../SubComponents/SummaryTable/SummaryTable";
import {
  createQuestionSet,
  deleteQuestionFromSet,
  questionsForQSet,
  updateSetMetadata,
} from "../../../api/questions";
import { fetchAccessLevel, fetchQuestionType } from "../../../api/qbMasterData";
import { boardList } from "../../../api/boards";
import {
  fetchCourse,
  fetchSubject,
  fetchSubSubject,
  fetchSubTopic,
  fetchTopic,
} from "../../../api/institution";
import { fetchMedium } from "../../../api/mediums";
import { Player } from "@lottiefiles/react-lottie-player";

const difficultyLevel = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
var institution_id = 0;
var question_type_id = -1;
var board_id = -1;
var course_id = -1;
var subject_id = -1;
var sub_subject_id = -1;
var topic_1_id = -1;
var link;
var linkFinal;
var unCheckedRowIndex;
var unCheckedRowIndexRemove;
var questionPresent = [];
var questionAddedArray = [];
var questionDeletedArray = [];
var isEdit = false;
var questionSetIdEdit = -1;
var editQuestionSetDetails;
var qSetBoard_id = -1;
var qSetCourse_id = -1;
var qSetSubject_id = -1;
var qSetSub_subject_id = -1;
var QSetTotalMarks = 0;

function QSetCreator() {
  const [formData, setFormData] = useState({
    question_type_id: "",
    board_id: "",
    course_id: "",
    subject_id: "",
    sub_subject_id: "",
    topic_1_id: "",
    sub_topic_1_id: "",
    difficulty_level: "",
    access_level_id: "",
    medium_id: "",
    question_code: "",
    added_by_user_id: 0,
  });
  const [state, setState] = useState({
    questionsType: [],
    medium: [],
    board: [],
    course: [],
    subject: [],
    subSubject: [],
    topic: [],
    subTopic: [],
    subTopic2: [],
    accessLevel: [],
  });
  const [startUpLoad, setStartUpLoad] = useState(true);
  // const [pageNumber, setPageNumber] = useState(1);
  // const [offSet, setOffSet] = useState(0);
  // const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const [noData, setNoData] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const [questionView, setQuestionView] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [selLink, setSelLink] = useState("");
  const [selData, setSelData] = useState();
  const [checkedRows, setCheckedRows] = useState([]);
  const [setSaved, setSetSaved] = useState(false);
  const [metadata, setMetadata] = useState({
    display_name: "",
    description: "",
    board_id: "",
    board: "",
    course_id: "",
    course: "",
    subject_id: "",
    subject: "",
    sub_subject_id: "",
    sub_subject: "",
    topic_id: "",
    topic: "",
  });
  console.log(metadata, "metadata");
  const [qSetState, setQSetState] = useState({
    board: [],
    course: [],
    subject: [],
    subSubject: [],
    topic: [],
  });
  const [setCode, setSetCode] = useState("");

  // console.log(setCode, metadata);

  const token = window.localStorage.getItem("token");
  const userId = window.localStorage.getItem("userId");
  const Auth = {
    headers: {
      authorization: "Bearer " + token,
    },
  };

  function handleChange(e, selLabel) {
    const valueSele = e.target.value;
    fetchOtherResults(e, selLabel);
    setFormData({
      ...formData,
      [selLabel]: valueSele,
    });
  }

  function handleQSetChange(e, selLabel) {
    const valueSele = e.target.value;
    console.log(selLabel);
    if (selLabel === "topic_1_id") {
      setMetadata((prevState) => {
        return { ...prevState, topic_id: valueSele };
      });
    } else {
      setMetadata((prevState) => {
        return { ...prevState, [selLabel]: valueSele };
      });
      fetchOtherQSetResults(e, selLabel);
    }
  }

  async function fetchOtherQSetResults(e, selLabel) {
    if (selLabel === "board_id") {
      setMetadata((prevState) => {
        return {
          ...prevState,
          course_id: -1,
          subject_id: -1,
          sub_subject_id: -1,
          topic_id: -1,
        };
      });
      setStartUpLoad(true);
      qSetBoard_id = e.target.value;
      qSetCourse_id = -1;
      qSetSubject_id = -1;
      qSetSub_subject_id = -1;
      try {
        // const getQSetCourse = await Axios.get(
        //   `${BASE_URL}/institutions/${institution_id}/boards/${qSetBoard_id}/courses`,
        //   Auth
        // );
        const getQSetCourse = await fetchCourse(institution_id, qSetBoard_id);
        setQSetState((prevState) => {
          return {
            ...prevState,
            course: getQSetCourse.data.data,
          };
        });
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === "course_id") {
      setMetadata((prevState) => {
        return {
          ...prevState,
          subject_id: -1,
          sub_subject_id: -1,
          topic_id: -1,
        };
      });
      setStartUpLoad(true);
      qSetCourse_id = e.target.value;
      qSetSubject_id = -1;
      qSetSub_subject_id = -1;
      try {
        // const getQSetSubject = await Axios.get(
        //   `${BASE_URL}/institutions/${institution_id}/boards/${qSetBoard_id}/courses/${qSetCourse_id}/subjects`,
        //   Auth
        // );
        const getQSetSubject = await fetchSubject(
          institution_id,
          qSetBoard_id,
          qSetCourse_id
        );
        setQSetState((prevState) => {
          return {
            ...prevState,
            subject: getQSetSubject.data.data,
          };
        });
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === "subject_id") {
      setMetadata((prevState) => {
        return { ...prevState, sub_subject_id: -1, topic_id: -1 };
      });
      setStartUpLoad(true);
      qSetSubject_id = e.target.value;
      qSetSub_subject_id = -1;
      try {
        // const getQSetSubSubject = await Axios.get(
        //   `${BASE_URL}/institutions/${institution_id}/boards/${qSetBoard_id}/courses/${qSetCourse_id}/subjects/${qSetSubject_id}/sub_subjects`,
        //   Auth
        // );
        const getQSetSubSubject = await fetchSubSubject(
          institution_id,
          qSetBoard_id,
          qSetCourse_id,
          qSetSubject_id
        );
        setQSetState((prevState) => ({
          ...prevState,
          subSubject: getQSetSubSubject.data.data,
        }));
        setStartUpLoad(false);
      } catch (err) {
        console.log(err, "error while fetching data");
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === "sub_subject_id") {
      setMetadata((prevState) => {
        return { ...prevState, topic_id: -1 };
      });
      setStartUpLoad(true);
      qSetSub_subject_id = e.target.value;
      try {
        // const getQSetTopic = await Axios.get(
        //   `${BASE_URL}/institutions/${institution_id}/boards/${qSetBoard_id}/courses/${qSetCourse_id}/subjects/${qSetSubject_id}/sub_subjects/${qSetSub_subject_id}/topics`,
        //   Auth
        // );
        const getQSetTopic = await fetchTopic(
          institution_id,
          qSetBoard_id,
          qSetCourse_id,
          qSetSubject_id,
          qSetSub_subject_id
        );
        setQSetState((prevState) => ({
          ...prevState,
          topic: getQSetTopic.data.data,
        }));
        setStartUpLoad(false);
      } catch (err) {
        console.log(err, "error while fetching data");
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
  }

  useEffect(() => {
    setStartUpLoad(true);
    async function fetchData() {
      try {
        // let getQuestionType = await Axios.get(
        //   `${BASE_URL}/qb/question_types`,
        //   Auth
        // );
        const getQuestionType = await fetchQuestionType();
        // const getAccessLevel = await Axios.get(
        //   `${BASE_URL}/qb/access_levels`,
        //   Auth
        // );
        const getAccessLevel = await fetchAccessLevel();
        // const getMediums = await Axios.get(`${BASE_URL}/mediums`, Auth);
        const getMediums = await fetchMedium();
        setState({
          ...state,
          questionsType: getQuestionType.data.data,
          accessLevel: getAccessLevel.data.data,
          medium: getMediums.data.data,
        });
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
    fetchData();
    setFormData((prevState) => {
      return { ...prevState, added_by_user_id: userId };
    });
    // eslint-disable-next-line
  }, []);

  async function fetchOtherResults(e, selLabel) {
    if (selLabel === "question_type_id") {
      question_type_id = e.target.value;
      board_id = -1;
      course_id = -1;
      subject_id = -1;
      sub_subject_id = -1;
      topic_1_id = -1;
      setFormData((prevState) => {
        return {
          ...prevState,
          board_id: "",
          course_id: "",
          subject_id: "",
          sub_subject_id: "",
          topic_1_id: "",
          sub_topic_1_id: "",
        };
      });
      setStartUpLoad(true);
      try {
        // const getBoard = await Axios.get(`${BASE_URL}/boards`, Auth);
        const getBoard = await boardList();
        setState({
          ...state,
          board: getBoard.data.data,
        });
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === "board_id") {
      setStartUpLoad(true);
      board_id = e.target.value;
      course_id = -1;
      subject_id = -1;
      sub_subject_id = -1;
      topic_1_id = -1;
      setFormData((prevState) => {
        return {
          ...prevState,
          course_id: "",
          subject_id: "",
          sub_subject_id: "",
          topic_1_id: "",
          sub_topic_1_id: "",
        };
      });
      try {
        // const getCourse = await Axios.get(
        //   `${BASE_URL}/institutions/${institution_id}/boards/${board_id}/courses`,
        //   Auth
        // );
        const getCourse = await fetchCourse(institution_id, board_id);
        setState({
          ...state,
          course: getCourse.data.data,
        });
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === "course_id") {
      setFormData((prevState) => {
        return {
          ...prevState,
          subject_id: "",
          sub_subject_id: "",
          topic_1_id: "",
          sub_topic_1_id: "",
        };
      });
      setStartUpLoad(true);
      course_id = e.target.value;
      subject_id = -1;
      sub_subject_id = -1;
      topic_1_id = -1;
      try {
        // const getSubject = await Axios.get(
        //   `${BASE_URL}/institutions/${institution_id}/boards/${board_id}/courses/${course_id}/subjects`,
        //   Auth
        // );
        const getSubject = await fetchSubject(
          institution_id,
          board_id,
          course_id
        );
        setState({
          ...state,
          subject: getSubject.data.data,
        });
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === "subject_id") {
      setFormData((prevState) => {
        return {
          ...prevState,
          sub_subject_id: "",
          topic_1_id: "",
          sub_topic_1_id: "",
        };
      });
      setStartUpLoad(true);
      subject_id = e.target.value;
      sub_subject_id = -1;
      topic_1_id = -1;
      try {
        // const getSubSubject = await Axios.get(
        //   `${BASE_URL}/institutions/${institution_id}/boards/${board_id}/courses/${course_id}/subjects/${subject_id}/sub_subjects`,
        //   Auth
        // );
        const getSubSubject = await fetchSubSubject(
          institution_id,
          board_id,
          course_id,
          subject_id
        );
        setState((prevState) => ({
          ...prevState,
          subSubject: getSubSubject.data.data,
        }));
        setStartUpLoad(false);
      } catch (err) {
        console.log(err, "error while fetching data");
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === "sub_subject_id") {
      setFormData((prevState) => {
        return { ...prevState, topic_1_id: "", sub_topic_1_id: "" };
      });
      setStartUpLoad(true);
      sub_subject_id = e.target.value;
      topic_1_id = -1;
      try {
        // const getTopic = await Axios.get(
        //   `${BASE_URL}/institutions/${institution_id}/boards/${board_id}/courses/${course_id}/subjects/${subject_id}/sub_subjects/${sub_subject_id}/topics`,
        //   Auth
        // );
        const getTopic = await fetchTopic(
          institution_id,
          board_id,
          course_id,
          subject_id,
          sub_subject_id
        );
        setState((prevState) => ({
          ...prevState,
          topic: getTopic.data.data,
        }));
        setStartUpLoad(false);
      } catch (err) {
        console.log(err, "error while fetching data");
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === "topic_1_id") {
      setFormData((prevState) => {
        return { ...prevState, sub_topic_1_id: "" };
      });
      setStartUpLoad(true);
      topic_1_id = e.target.value;
      try {
        // const getSubTopic = await Axios.get(
        //   `${BASE_URL}/institutions/${institution_id}/boards/${board_id}/courses/${course_id}/subjects/${subject_id}/sub_subjects/${sub_subject_id}/topics/${topic_1_id}/sub_topics`,
        //   Auth
        // );
        const getSubTopic = await fetchSubTopic(
          institution_id,
          board_id,
          course_id,
          subject_id,
          sub_subject_id,
          topic_1_id
        );
        console.log(getSubTopic.data.data);
        setState((prevState) => ({
          ...prevState,
          subTopic: getSubTopic.data.data,
        }));
        setStartUpLoad(false);
      } catch (err) {
        console.log(err, "error while fetching data");
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === "sub_topic_1_id") {
      setStartUpLoad(true);
      // sub_topic_1_id = e.target.value;
      try {
        // const getAccessLevel = await Axios.get(
        //   `${BASE_URL}/qb/access_levels`,
        //   Auth
        // );
        const getAccessLevel = await fetchAccessLevel();
        setState({
          ...state,
          accessLevel: getAccessLevel.data.data,
        });
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
  }

  //function to search questions
  async function handleSubmit(e) {
    e.preventDefault();
    if (
      question_type_id === -1 ||
      board_id === -1 ||
      course_id === -1 ||
      subject_id === -1
    ) {
      toast.error("Please Select Options Till Subject");
    } else {
      setSubmitLoading(true);
      setNoData(false);
      setSetSaved(false);
      link = [];
      if (formData.question_type_id && +formData.question_type_id !== -1) {
        link.push(`question_type_id=${formData.question_type_id}`);
      }
      if (formData.board_id && +formData.board_id !== -1) {
        link.push(`board_id=${formData.board_id}`);
      }
      if (formData.course_id && +formData.course_id !== -1) {
        link.push(`course_id=${formData.course_id}`);
      }
      if (formData.subject_id && +formData.subject_id !== -1) {
        link.push(`subject_id=${formData.subject_id}`);
      }
      if (formData.sub_subject_id && +formData.sub_subject_id !== -1) {
        link.push(`sub_subject_id=${formData.sub_subject_id}`);
      }
      if (formData.topic_1_id && +formData.topic_1_id !== -1) {
        link.push(`topic_1_id=${formData.topic_1_id}`);
      }
      if (formData.sub_topic_1_id && +formData.sub_topic_1_id !== -1) {
        link.push(`sub_topic_1_id=${formData.sub_topic_1_id}`);
      }
      if (formData.topic_2_id && +formData.topic_2_id !== -1) {
        link.push(`topic_2_id=${formData.topic_2_id}`);
      }
      if (formData.sub_topic_2_id && +formData.sub_topic_2_id !== -1) {
        link.push(`sub_topic_2_id=${formData.sub_topic_2_id}`);
      }
      if (formData.difficulty_level && +formData.difficulty_level !== -1) {
        link.push(`difficulty_level=${formData.difficulty_level}`);
      }
      if (formData.access_level_id && +formData.access_level_id !== -1) {
        link.push(`access_level_id=${formData.access_level_id}`);
      }
      if (formData.medium_id && +formData.medium_id !== -1) {
        link.push(`medium_id=${formData.medium_id}`);
      }
      if (formData.added_by_user_id) {
        // link.push(`added_by_user_id=${formData.added_by_user_id}`);
        link.push(`user_id=${formData.added_by_user_id}`);
      }
      linkFinal = link.join("&");
      console.log(linkFinal);
      console.log(formData);
      const AuthSub = {
        headers: {
          authorization: "Bearer " + token,
        },
      };
      try {
        // const getNumberOfQuestions = await Axios.get(
        //   `${BASE_URL}/qb/questions/count_questions?${linkFinal}`,
        //   Auth
        // );
        // console.log(getNumberOfQuestions.data.data.count);
        const getQuestions = await Axios.get(
          // `${BASE_URL}/qb/questions/search?${linkFinal}&limit=10&offset=${offSet}`,
          `${BASE_URL}/qb/questions/search?${linkFinal}`,
          {
            headers: AuthSub.headers,
          }
        );
        console.log(getQuestions.data.data);
        if (isEdit) {
          var tempQuestionIdList = checkedRows.map((question) => {
            return +question.question_id;
          });
          console.log(tempQuestionIdList);
          var tempUncheckedList = getQuestions.data.data.filter((question) => {
            return !tempQuestionIdList.includes(+question.question_id);
          });
          console.log(tempUncheckedList);
          setQuestionList(tempUncheckedList);
          setQuestionView(true);
          setSubmitLoading(false);
          window.scrollTo(0, 600);
        } else {
          // setNumberOfQuestions(getNumberOfQuestions.data.data.count);
          setQuestionList(getQuestions.data.data);
          if (getQuestions.data.data.length > 0) {
            setQuestionView(true);
            setSubmitLoading(false);
            window.scrollTo(0, 600);
          } else {
            setQuestionView(false);
            setSubmitLoading(false);
            setNoData(true);
          }
        }
      } catch (err) {
        setNoData(true);
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setSubmitLoading(false);
      }
    }
  }

  // useEffect(() => {
  //   async function handlePagination() {
  //     console.log(offSet);
  //     const AuthSub1 = {
  //       headers: {
  //         authorization: "Bearer " + token,
  //       },
  //     };
  //     try {
  //       const getQuestions = await Axios.get(
  //         `${BASE_URL}/qb/questions/search?${linkFinal}&limit=10&offset=${offSet}`,
  //         {
  //           headers: AuthSub1.headers,
  //         }
  //       );

  //       console.log(getQuestions.data.data);
  //       setQuestionList(getQuestions.data.data);
  //       setStartUpLoad(false);
  //     } catch (err) {
  //       if (err.response && err.response.data) {
  //         console.log(err.response.data.message);
  //         toast.error(err.response.data.message);
  //       }else {
  // toast.error("Server Error");
  // }
  //       setStartUpLoad(false);
  //     }
  //   }
  //   handlePagination();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [offSet]);

  function handleClick(SelQue) {
    console.log(SelQue);
    setSelLink(SelQue.image_path);
    setSelData(SelQue);
    // window.scrollTo(0, 2000);
  }

  function handleCheckbox(event, row) {
    var isChecked = event.target.checked;
    if (isChecked === true) {
      setCheckedRows([...checkedRows, row]);
      let tempList = questionList.map((question) => {
        if (question.question_id === row.question_id) {
          return { ...question, isChecked: true };
        } else {
          return question;
        }
      });
      console.log(isEdit);
      if (isEdit) {
        if (!questionPresent.includes(row.question_id)) {
          questionAddedArray.push(row.question_id);
        }
        console.log(questionAddedArray);

        if (questionDeletedArray.includes(row.question_id)) {
          var deletedQuestionIndex = questionDeletedArray.indexOf(
            row.question_id
          );
          questionDeletedArray.splice(deletedQuestionIndex, 1);
          console.log(questionDeletedArray);
        }
      }
      // console.log(tempList);
      setQuestionList(tempList);
    }
    if (isChecked === false) {
      var tempCheckedRows = checkedRows;
      checkedRows.forEach((rowObject, rowIndex) => {
        // console.log(row.question_id, rowObject.question_id, rowIndex);
        if (row.question_id === rowObject.question_id) {
          unCheckedRowIndex = rowIndex;
        }
      });
      tempCheckedRows.splice(unCheckedRowIndex, 1);
      // console.log(tempCheckedRows);
      let tempList = questionList.map((question) => {
        return question.question_id === row.question_id
          ? { ...question, isChecked: false }
          : question;
      });
      if (isEdit) {
        if (questionAddedArray.includes(row.question_id)) {
          var remIndex = questionAddedArray.indexOf(row.question_id);
          questionAddedArray.splice(remIndex, 1);
        }
        console.log(questionAddedArray);
      }
      // console.log(tempList);
      // console.log(unCheckedRowIndex);

      setCheckedRows(tempCheckedRows);
      setQuestionList(tempList);
    }
  }
  // console.log(checkedRows);

  async function handleSaveSet() {
    setStartUpLoad(true);
    if (isEdit) {
      const editData = {
        institution_id: institution_id,
        // board_id: editQuestionSetDetails[0].board_id,
        // course_id: editQuestionSetDetails[0].course_id,
        // subject_id: editQuestionSetDetails[0].subject_id,
        added_by_user_id: editQuestionSetDetails[0].added_by_user_id,
        question_ids: questionAddedArray,
        question_set_id: +questionSetIdEdit,
      };
      console.log(editData);
      try {
        if (questionAddedArray.length > 0) {
          // const sendSetEditData = await Axios.post(
          //   `${BASE_URL}/qb/question_sets`,
          //   editData,
          //   Auth
          // );
          const sendSetEditData = await createQuestionSet(editData);
          sendSetEditData.data.data && console.log(sendSetEditData.data.data);
        }
        if (questionDeletedArray.length > 0) {
          questionDeletedArray.forEach(async (queId) => {
            // const deleteSetQuestions = await Axios.delete(
            //   `${BASE_URL}/qb/question_set_questions/${questionSetIdEdit}/${queId}`,
            //   Auth
            // );
            const deleteSetQuestions = await deleteQuestionFromSet(
              questionSetIdEdit,
              queId
            );
            deleteSetQuestions.data.data &&
              console.log(deleteSetQuestions.data.data);
          });
        }
        // const getQSetBoard = await Axios.get(`${BASE_URL}/boards`, Auth);
        const getQSetBoard = await boardList();
        console.log(getQSetBoard.data.data);
        setQSetState((prevState) => {
          return {
            ...prevState,
            board: getQSetBoard.data.data,
          };
        });
        setStartUpLoad(false);
      } catch (error) {
        if (error.response && error.response.data) {
          toast.error(error.response.data.message);
          console.log(error.response.data.message);
          setStartUpLoad(false);
        } else {
          setStartUpLoad(false);
          toast.error("Server Error");
        }
      }
    } else {
      const questionIds = checkedRows.map((ques, quesIndex) => {
        return ques.question_id;
      });
      const setData = {
        institution_id: institution_id,
        // board_id: board_id,
        // course_id: course_id,
        // subject_id: subject_id,
        added_by_user_id: userId,
        question_ids: questionIds,
      };
      console.log(setData);
      try {
        // const sendSetData = await Axios.post(
        //   `${BASE_URL}/qb/question_sets`,
        //   setData,
        //   Auth
        // );
        const sendSetData = await createQuestionSet(setData);
        console.log(sendSetData.data.data.o_question_set_id);
        // const getQSetBoard = await Axios.get(`${BASE_URL}/boards`, Auth);
        const getQSetBoard = await boardList();
        console.log(getQSetBoard.data.data);
        setQSetState((prevState) => {
          return {
            ...prevState,
            board: getQSetBoard.data.data,
          };
        });
        setSetCode(sendSetData.data.data.o_question_set_id);
        toast.success(
          "Question set saved successfully, give name and description for question set"
        );
        setStartUpLoad(false);
      } catch (error) {
        if (error.response && error.response.data) {
          toast.error(error.response.data.message);
          console.log(error.response.data.message);
          setStartUpLoad(false);
        } else {
          setStartUpLoad(false);
          toast.error("Server Error");
        }
      }
    }
    setQuestionView(false);
    setSetSaved(true);
  }

  function handleQueRemove(row) {
    var tempCheckedRowsRemove = checkedRows;
    checkedRows.forEach((rowObject, rowIndex) => {
      console.log(row.question_id, rowObject.question_id, rowIndex);
      if (row.question_id === rowObject.question_id) {
        unCheckedRowIndexRemove = rowIndex;
      }
    });
    tempCheckedRowsRemove.splice(unCheckedRowIndexRemove, 1);

    let tempList = questionList.map((question) => {
      return question.question_id === row.question_id
        ? { ...question, isChecked: false }
        : question;
    });
    console.log(tempList);
    console.log(unCheckedRowIndex);

    if (isEdit) {
      if (questionAddedArray.includes(row.question_id)) {
        var remIndex = questionAddedArray.indexOf(row.question_id);
        questionAddedArray.splice(remIndex, 1);
      }
      if (questionPresent.includes(row.question_id)) {
        questionDeletedArray.push(row.question_id);
      }
      console.log(questionDeletedArray);
      console.log(questionAddedArray);
    }

    setCheckedRows(tempCheckedRowsRemove);
    setQuestionList(tempList);
  }

  useEffect(() => {
    setStartUpLoad(true);
    isEdit = window.localStorage.getItem("isEdit");
    questionSetIdEdit = window.localStorage.getItem("QSetCode");
    window.localStorage.removeItem("isEdit");
    window.localStorage.removeItem("QSetCode");
    async function fetchQSetEditDropdown() {
      try {
        // const getQSetBoard = await Axios.get(`${BASE_URL}/boards`, Auth);
        const getQSetBoard = await boardList();
        // const getQSetCourse = await Axios.get(
        //   `${BASE_URL}/institutions/${institution_id}/boards/${qSetBoard_id}/courses`,
        //   Auth
        // );
        const getQSetCourse = await fetchCourse(institution_id, qSetBoard_id);
        setQSetState((prevState) => ({
          ...prevState,
          board: getQSetBoard.data.data,
          course: getQSetCourse.data.data,
        }));
        if (qSetCourse_id) {
          // const getQSetSubject = await Axios.get(
          //   `${BASE_URL}/institutions/${institution_id}/boards/${qSetBoard_id}/courses/${qSetCourse_id}/subjects`,
          //   Auth
          // );
          const getQSetSubject = await fetchSubject(
            institution_id,
            qSetBoard_id,
            qSetCourse_id
          );
          setQSetState((prevState) => ({
            ...prevState,
            subject: getQSetSubject.data.data,
          }));
          if (qSetSubject_id) {
            // const getQSetSubSubject = await Axios.get(
            //   `${BASE_URL}/institutions/${institution_id}/boards/${qSetBoard_id}/courses/${qSetCourse_id}/subjects/${qSetSubject_id}/sub_subjects`,
            //   Auth
            // );
            const getQSetSubSubject = await fetchSubSubject(
              institution_id,
              qSetBoard_id,
              qSetCourse_id,
              qSetSubject_id
            );
            setQSetState((prevState) => ({
              ...prevState,
              subSubject: getQSetSubSubject.data.data,
            }));
            if (qSetSub_subject_id) {
              // const getQSetTopic = await Axios.get(
              //   `${BASE_URL}/institutions/${institution_id}/boards/${qSetBoard_id}/courses/${qSetCourse_id}/subjects/${qSetSubject_id}/sub_subjects/${qSetSub_subject_id}/topics`,
              //   Auth
              // );
              const getQSetTopic = await fetchTopic(
                institution_id,
                qSetBoard_id,
                qSetCourse_id,
                qSetSubject_id,
                qSetSub_subject_id
              );
              console.log(getQSetTopic.data.data);
              setQSetState((prevState) => ({
                ...prevState,
                topic: getQSetTopic.data.data,
              }));
            }
          }
        }
      } catch (error) {
        if (error.response && error.response.data) {
          console.log(error.response.data.message);
          toast.error(error.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
    async function fetchSelQuestions() {
      if (isEdit) {
        try {
          // const getSelQuestions = await Axios.get(
          //   `${BASE_URL}/qb/question_sets_questions/${questionSetIdEdit}`,
          //   Auth
          // );
          const getSelQuestions = await questionsForQSet(questionSetIdEdit);
          console.log(getSelQuestions.data.data);
          const getQuestionSetDetails = await Axios.get(
            `${BASE_URL}/qb/question_sets/search?question_set_id=${questionSetIdEdit}&limit=1&offset=0`,
            Auth
          );
          if (getQuestionSetDetails.data.data) {
            console.log(getQuestionSetDetails.data.data);
            editQuestionSetDetails = getQuestionSetDetails.data.data;
            qSetBoard_id = editQuestionSetDetails[0].board_id;
            qSetCourse_id = editQuestionSetDetails[0].course_id;
            qSetSubject_id = editQuestionSetDetails[0].subject_id;
            qSetSub_subject_id = editQuestionSetDetails[0].sub_subject_id;
            // qSetTopic_id = editQuestionSetDetails[0].topic_id;
            setSetCode(questionSetIdEdit);
            setMetadata({
              ...metadata,
              display_name: editQuestionSetDetails[0].question_set_name,
              description: editQuestionSetDetails[0].description,
              board_id: editQuestionSetDetails[0].board_id,
              board: editQuestionSetDetails[0].board,
              course_id: editQuestionSetDetails[0].course_id,
              course: editQuestionSetDetails[0].course,
              subject_id: editQuestionSetDetails[0].subject_id,
              subject: editQuestionSetDetails[0].subject,
              sub_subject_id: editQuestionSetDetails[0].sub_subject_id,
              sub_subject: editQuestionSetDetails[0].sub_subject,
              topic_id: editQuestionSetDetails[0].topic_id,
              topic: editQuestionSetDetails[0].topic,
            });
            if (getSelQuestions.data.data) {
              questionPresent = getSelQuestions.data.data.map(
                (question, queIndex) => {
                  return question.question_id;
                }
              );
              console.log(questionPresent);
              setCheckedRows(getSelQuestions.data.data);
            } else {
              toast.error("Data not available");
            }
          }
          await fetchQSetEditDropdown();
          setStartUpLoad(false);
        } catch (error) {
          if (error.response && error.response.data) {
            console.log(error.response.data.message);
            toast.error(error.response.data.message);
          } else {
            toast.error("Server Error");
          }
          setStartUpLoad(false);
        }
      }
    }

    fetchSelQuestions();
    setFormData((prevState) => {
      return { ...prevState, added_by_user_id: userId };
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSetMetadata(event, selInput) {
    const inputValue = event.target.value;
    console.log(inputValue);
    setMetadata({ ...metadata, [selInput]: inputValue });
  }

  async function saveSetMetadata() {
    if (
      metadata.display_name === "" ||
      metadata.description === "" ||
      metadata.board_id === "" ||
      metadata.board_id === -1 ||
      metadata.course_id === "" ||
      metadata.course_id === -1 ||
      Number(metadata.subject_id) === -1 ||
      metadata.subject_id === ""
    ) {
      toast.error("Please fill mandatory fields");
    } else {
      setStartUpLoad(true);
      console.log(metadata);
      try {
        // const metaDataSave = await Axios.put(
        //   `${BASE_URL}/qb/question_sets/${setCode}`,
        //   metadata,
        //   Auth
        // );
        const metaDataSave = await updateSetMetadata(setCode, metadata);
        console.log(metaDataSave.data);
        setStartUpLoad(false);
        toast.success("Question metadata saved successfully");
        window.location = "/admin/q-set-view";
        // window.location.reload(false);
      } catch (error) {
        if (error.response && error.response.data) {
          toast.error(error.response.data.message);
          console.log(error.response.data.message);
          setStartUpLoad(false);
        } else {
          setStartUpLoad(false);
          toast.error("Server Error");
        }
      }
    }
  }

  if (checkedRows.length > 0) {
    QSetTotalMarks = 0;
    checkedRows.forEach((row) => {
      QSetTotalMarks = QSetTotalMarks + row.max_marks;
    });
  }

  // function paginationClick(number, offSetNumber) {
  //   setPageNumber(+number);
  //   setOffSet(+offSetNumber);
  //   setStartUpLoad(true);
  // }

  return (
    <div>
      {startUpLoad ? (
        <div className="loadingBackground">
          <ReactLoading type="spin" color="#68dff0" className="startLoadSpin" />
        </div>
      ) : null}
      <form>
        <div className="queContainer">
          <h1 className="viewH3">
            <ArrowForwardIosIcon className="viewH3Arrow" />
            QSet Creator
          </h1>
        </div>
        <div className="searchQuestionContainer">
          <h4 className="searchQuestionHeading">Create Questions Set</h4>
          <Dropdown
            label="Questions Type"
            options={state.questionsType}
            displayName="display_name"
            name="question_type_id"
            idName="id"
            onChange={handleChange}
            req={true}
          >
            {" "}
          </Dropdown>
          <Dropdown
            label="Board"
            options={state.board}
            name="board_id"
            displayName="board_display_name"
            idName="board_id"
            onChange={handleChange}
            req={true}
          />
          <Dropdown
            label="Course"
            options={state.course}
            name="course_id"
            displayName="display_name"
            idName="course_id"
            onChange={handleChange}
            req={true}
          />
          <Dropdown
            label="Subject"
            options={state.subject}
            name="subject_id"
            displayName="display_name"
            idName="subject_id"
            onChange={handleChange}
            req={true}
          />
          <Dropdown
            label="Sub-Subject"
            options={state.subSubject}
            name="sub_subject_id"
            displayName="display_name"
            idName="sub_subject_id"
            onChange={handleChange}
            req={false}
            displayEngName={"display_name_eng"}
          />
          <Dropdown
            label="Topic"
            options={state.topic}
            name="topic_1_id"
            displayName="display_name"
            idName="topic_id"
            onChange={handleChange}
            req={false}
            displayEngName={"display_name_eng"}
          />
          <Dropdown
            label="Sub-Topic"
            options={state.subTopic}
            name="sub_topic_1_id"
            displayName="display_name"
            idName="sub_topic_id"
            onChange={handleChange}
            req={false}
            displayEngName={"display_name_eng"}
          />
          <Dropdown
            label="Difficulty Level"
            options={difficultyLevel}
            name="difficulty_level"
            displayName=""
            onChange={handleChange}
            req={false}
          />
          <Dropdown
            label="Access Level"
            options={state.accessLevel}
            displayName="display_name"
            name="access_level_id"
            idName="id"
            onChange={handleChange}
            req={false}
          />
          <Dropdown
            label="Medium"
            options={state.medium}
            name="medium_id"
            displayName="medium_name"
            idName="medium_id"
            onChange={handleChange}
            req={false}
          />
        </div>
        <div className="viewContainer">
          <button className="viewSearch" onClick={(e) => handleSubmit(e)}>
            Search
          </button>
          {submitLoading ? (
            <div className="loadingDiv">
              <ReactLoading
                type="spinningBubbles"
                color="#68dff0"
                className="reactLoadingIcon"
              />
              <h5 className="loadingText">Loading</h5>
            </div>
          ) : null}
          {noData ? (
            <div className="loadingDiv">
              <h5 className="loadingTextData">Data is not available</h5>
            </div>
          ) : null}
        </div>
      </form>
      {checkedRows.length > 0 && setSaved === false && (
        <div className="viewSetContainer">
          {/* <button className="viewSearchOne">Add To Set</button> */}
          <button className="viewSetSearchTwo" onClick={handleSaveSet}>
            Save Set
          </button>
        </div>
      )}
      {setSaved && (
        <div className="compulsoryContainerViewQuestions">
          <h4 className="compulsoryHeading">QSet</h4>
          <div className="viewQuestionsSearchDisplay">
            <div className="viewQSetTableContainer">
              {checkedRows.map((row, questionKey) => {
                return (
                  <div key={questionKey}>
                    <div
                      className="viewResultRowDiv"
                      // onClick={(e) => handleClick(row)}
                    >
                      <div className="qsetNumberDiv">
                        <p>{questionKey + 1}</p>
                      </div>
                      <div className="qsetShortDiv">
                        <p className="viewShortP">{row.short_description}</p>
                        <div className="viewByLastDiv">
                          {row.access_level === "Global" && (
                            <PublicIcon
                              className="viewPublicIcon"
                              fontSize="small"
                            />
                          )}
                          {row.access_level === "Organisation" && (
                            <AccountBalanceIcon
                              className="viewPublicIcon"
                              fontSize="small"
                            />
                          )}
                          {row.access_level === "Private" && (
                            <PersonIcon
                              className="viewPublicIcon"
                              fontSize="small"
                            />
                          )}
                          <p className="viewBy">By: {row.created_by}</p>
                          <p className="viewLastUpdated">
                            Last Updated: {row.last_updated}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="setSummaryNameDiv">
              <div
                className={isEdit ? "setSaveLabelPDiv" : "setSaveLabelInputDiv"}
              >
                <label
                  className={
                    isEdit ? "setSaveLabelSummaryNoStar" : "setSaveLabelSummary"
                  }
                >
                  QSet Name
                </label>
                {isEdit ? (
                  <p className="setSaveSummaryP">{metadata.display_name}</p>
                ) : (
                  <input
                    className="setSaveInputSummary"
                    onChange={(event) =>
                      handleSetMetadata(event, "display_name")
                    }
                    value={metadata.display_name}
                  ></input>
                )}
              </div>
              <div className="setSaveLabelInputDiv">
                <label
                  className={
                    isEdit ? "setSaveLabelSummaryNoStar" : "setSaveLabelSummary"
                  }
                >
                  Description
                </label>
                <textarea
                  className="setSaveTextareaSummary"
                  onChange={(event) => handleSetMetadata(event, "description")}
                  value={metadata.description}
                ></textarea>
              </div>
              <div className="setSaveLabelPDiv">
                <label
                  className={
                    isEdit ? "setSaveLabelSummaryNoStar" : "setSaveLabelSummary"
                  }
                >
                  QSet Code
                </label>
                <p className="setSaveSummaryP">{setCode}</p>
              </div>
              {isEdit ? (
                <div className="setSaveLabelPDiv">
                  <label className="setSaveLabelSummaryNoStar">Board</label>
                  <p className="setSaveSummaryP">{metadata.board}</p>
                </div>
              ) : (
                <div className="searchQuestionDropdown">
                  <DropdownQSet
                    label="Board"
                    options={qSetState.board}
                    name="board_id"
                    displayName="board_display_name"
                    idName="board_id"
                    onChange={handleQSetChange}
                    value={metadata.board_id}
                    req={true}
                  />
                </div>
              )}
              {isEdit ? (
                <div className="setSaveLabelPDiv">
                  <label className="setSaveLabelSummaryNoStar">Course</label>
                  <p className="setSaveSummaryP">{metadata.course}</p>
                </div>
              ) : (
                <div className="searchQuestionDropdown">
                  <DropdownQSet
                    label="Course"
                    options={qSetState.course}
                    name="course_id"
                    displayName="display_name"
                    idName="course_id"
                    onChange={handleQSetChange}
                    value={metadata.course_id}
                    req={true}
                  />
                </div>
              )}
              {isEdit ? (
                <div className="setSaveLabelPDiv">
                  <label className="setSaveLabelSummaryNoStar">Subject</label>
                  <p className="setSaveSummaryP">
                    {metadata.subject ? metadata.subject : "Not Specified"}
                  </p>
                </div>
              ) : (
                <div className="searchQuestionDropdown">
                  <DropdownQSet
                    label="Subject"
                    options={qSetState.subject}
                    name="subject_id"
                    displayName="display_name"
                    idName="subject_id"
                    onChange={handleQSetChange}
                    value={metadata.subject_id}
                    req={true}
                  />
                </div>
              )}
              {isEdit ? (
                <div className="setSaveLabelPDiv">
                  <label className="setSaveLabelSummaryNoStar">
                    Sub Subject
                  </label>
                  <p className="setSaveSummaryP">
                    {metadata.sub_subject
                      ? metadata.sub_subject
                      : "Not Specified"}
                  </p>
                </div>
              ) : (
                <div className="searchQuestionDropdown">
                  <DropdownQSet
                    label="Sub-Subject"
                    options={qSetState.subSubject}
                    name="sub_subject_id"
                    displayName="display_name"
                    idName="sub_subject_id"
                    onChange={handleQSetChange}
                    value={metadata.sub_subject_id}
                    req={false}
                  />
                </div>
              )}
              {isEdit ? (
                <div className="setSaveLabelPDiv">
                  <label className="setSaveLabelSummaryNoStar">Topic</label>
                  <p className="setSaveSummaryP">
                    {metadata.topic ? metadata.topic : "Not Specified"}
                  </p>
                </div>
              ) : (
                <div className="searchQuestionDropdown">
                  <DropdownQSet
                    label="Topic"
                    options={qSetState.topic}
                    name="topic_1_id"
                    displayName="display_name"
                    idName="topic_id"
                    onChange={handleQSetChange}
                    value={metadata.topic_id}
                    req={false}
                  />
                </div>
              )}
              <div>
                <button
                  className="summarySaveSetButton"
                  type="button"
                  onClick={saveSetMetadata}
                >
                  Save Set
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {checkedRows.length > 0 && setSaved === false && (
        <div className="compulsoryContainerViewQuestions">
          <div className="QSetCreatorh4PDiv">
            <h4 className="compulsoryHeading">{`QSet Questions: ${checkedRows.length}`}</h4>
            <p className="qSetCreatorTotalMarksP">
              Total Marks = {QSetTotalMarks}
            </p>
          </div>
          <div className="viewQuestionsSearchDisplay">
            <div className="viewQSetTableContainer">
              <div>
                {checkedRows.map((row, questionKey) => {
                  return (
                    <div key={questionKey}>
                      <div
                        className="viewResultRowDiv"
                        // onClick={(e) => handleClick(row)}
                      >
                        <div className="qsetNumberDiv">
                          <p>{questionKey + 1}</p>
                        </div>
                        <div className="qsetShortDiv">
                          <p className="viewShortP">{row.short_description}</p>
                          <div className="viewByLastDiv">
                            {row.access_level === "Global" && (
                              <PublicIcon
                                className="viewPublicIcon"
                                fontSize="small"
                              />
                            )}
                            {row.access_level === "Organisation" && (
                              <AccountBalanceIcon
                                className="viewPublicIcon"
                                fontSize="small"
                              />
                            )}
                            {row.access_level === "Private" && (
                              <PersonIcon
                                className="viewPublicIcon"
                                fontSize="small"
                              />
                            )}
                            <p className="viewBy">By: {row.created_by}</p>
                            <p className="viewLastUpdated">
                              Max Marks: {row.max_marks}
                            </p>
                          </div>
                          <p className="viewTopic">Topic: {row.topic}</p>
                          <p className="viewTopic">
                            Sub-Topic: {row.sub_topic}
                          </p>
                        </div>
                        <div>
                          <button
                            className="setRemoveButton"
                            onClick={(e) => handleQueRemove(row)}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="QSetCreatorSummaryDiv">
              <Marks checkedRows={checkedRows} />
              <br />
              <TopicTable checkedRows={checkedRows} />
              <br />
              <SubTopicTable checkedRows={checkedRows} />
              <br />
              <DifficultyLevel checkedRows={checkedRows} />
              <br />
              <Objective checkedRows={checkedRows} />
            </div>
          </div>
        </div>
      )}
      <div>
        {questionView ? (
          <div className="compulsoryContainerViewQuestions">
            <h4 className="compulsoryHeading">Questions</h4>
            <div className="viewQuestionsSearchDisplay">
              <div className="viewTablePaginationQSet">
                <div className="viewTableContainer">
                  <div>
                    {questionList.map((row, questionKey) => {
                      return (
                        <div key={questionKey}>
                          <div
                            className="viewResultRowDiv"
                            onClick={(e) => handleClick(row)}
                          >
                            <div className="qsetNumberDiv">
                              <p>{questionKey + 1}</p>
                            </div>
                            <div className="qsetShortDiv">
                              <p className="viewShortP">
                                {row.short_description}
                              </p>
                              <div className="viewByLastDiv">
                                {row.access_level === "Global" && (
                                  <PublicIcon
                                    className="viewPublicIcon"
                                    fontSize="small"
                                  />
                                )}
                                {row.access_level === "Organisation" && (
                                  <AccountBalanceIcon
                                    className="viewPublicIcon"
                                    fontSize="small"
                                  />
                                )}
                                {row.access_level === "Private" && (
                                  <PersonIcon
                                    className="viewPublicIcon"
                                    fontSize="small"
                                  />
                                )}
                                <p className="viewBy">By: {row.created_by}</p>
                                <p className="viewLastUpdated">
                                  Last Updated: {row.last_updated}
                                </p>
                              </div>
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                className="qsetCheckbox"
                                onChange={(e) => handleCheckbox(e, row)}
                                checked={row.isChecked ? row.isChecked : false}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {/* <Pagination
                  paginationClick={paginationClick}
                  numberOfRows={numberOfQuestions}
                  offSet={offSet}
                  pageNumber={pageNumber}
                /> */}
              </div>
              <div>
                {selLink !== "" ? (
                  <div>
                    <p className="viewImageAbove">
                      #{selData.question_id}, Topic: {selData.topic} ,
                      Sub-topic: {selData.sub_topic}
                    </p>
                    <div className="viewImageContainer ">
                      {selLink?.endsWith(".json") ? (
                        <Player
                          autoplay
                          loop
                          src={selLink}
                          style={{ width: "100%", height: "450px" }}
                        />
                      ) : selLink.endsWith(".webm") ? (
                        <video
                          controls
                          name="media"
                          style={{ width: "100%", height: "480px" }}
                        >
                          <source src={selLink} type="video/webm" />
                        </video>
                      ) : (
                        <img
                          src={selLink}
                          alt="UpLoaded"
                          className="backlogBigImage"
                        />
                      )}
                    </div>
                    {selData.response_type === "MCQ" && (
                      <p className="viewImageBelow">
                        Answer:{" "}
                        {selData.answer.map((object, objectKey) => {
                          return objectKey === selData.answer.length - 1
                            ? object.option_text
                            : object.option_text + ", ";
                        })}
                      </p>
                    )}
                    {selData.response_type === "Yes/No" && (
                      <p className="viewImageBelow">
                        Answer: {selData.answer.correct_answer}
                      </p>
                    )}
                    {selData.response_type === "0-10" && (
                      <p className="viewImageBelow">
                        Answer: {selData.answer.correct_answer}
                      </p>
                    )}
                    {selData.response_type === "MAQ" && (
                      <p className="viewImageBelow">
                        Answer:{" "}
                        {selData.answer.map((object, objectKey) => {
                          return objectKey === selData.answer.length - 1
                            ? object.option_text
                            : object.option_text + ", ";
                        })}
                      </p>
                    )}
                    {selData.response_type === "Matching " && (
                      <p className="viewImageBelow">
                        Answer:{" "}
                        {selData.answer.map((object, objectKey) => {
                          return objectKey === selData.answer.length - 1
                            ? object.left_hand_option +
                                "-" +
                                object.right_hand_option
                            : object.left_hand_option +
                                "-" +
                                object.right_hand_option +
                                ", ";
                        })}
                      </p>
                    )}
                    {selData.response_type === "One Word Answer" && (
                      <p className="viewImageBelow">
                        Answer: {selData.answer.correct_answer}
                      </p>
                    )}
                    {selData.response_type === "FITB" && (
                      <p className="viewImageBelow">
                        Answer: {selData.answer.correct_answer}
                      </p>
                    )}
                    {selData.response_type === "Text" && (
                      <p className="viewImageBelow">
                        Answer: {selData.answer.correct_answer}
                      </p>
                    )}
                    {(selData.response_type === "Image" ||
                      selData.response_type === "Voice" ||
                      selData.response_type === "Video") && (
                      <a
                        className="viewImageBelowFile"
                        href={selData.answer.answer_file_path}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        Answer: File.
                      </a>
                    )}
                    {/* {(selData.response_type === "Image" || selData.response_type === "Voice" ||
                      selData.response_type === "Video") && (
                      <div
                        className="viewImageBelowFile"
                        onClick={(e) => downloadFile(selData.answer.answer_file_path)}
                      >
                        Answer: File.
                      </div>
                    )} */}
                    {selData.response_type === "Files" && (
                      <a
                        className="viewImageBelowFile"
                        href={selData.answer[0].answer_file_path}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        Answer: File.
                      </a>
                    )}
                    <p className="">Reference: {selData.reference}</p>
                    <p>Difficulty: {selData.difficulty_level}</p>
                    <p>Objective: {selData.objective}</p>
                    {/* <div className="viewAddContainer">
                      <button
                        className="migrateButton"
                        // onClick={handleEditDetails}
                      >
                        Edit Details
                      </button>
                    </div> */}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default QSetCreator;
