import React from "react";
import "./tableTopicReport.css";

function TableTopicReport({ tableData, subSubject }) {
  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Sl. No.</th>
            <th className="topicReportThCourse">Course</th>
            <th className="topicReportThSubject">Subject</th>
            {subSubject ? (
              <th className="topicReportThSubject">Sub-Subject</th>
            ) : null}
            <th className="topicReportThTopic">Topic</th>
            <th>No. Of Questions</th>
            {Array.apply(null, { length: 10 }).map((e, keyDiff) => {
              return <th key={keyDiff}>D. Level {keyDiff + 1}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, tableKey) => {
            return (
              <tr key={tableKey}>
                <td>{row?.s_no}</td>
                <td>{row.course_name}</td>
                <td>{row.subject_name}</td>
                {subSubject ? <td>{row.sub_subject_name}</td> : null}
                <td>{row.topic_name}</td>
                <td>{row.no_of_questions}</td>
                {Array.apply(null, { length: 10 }).map((e, bodyDiff) => {
                  return (
                    <td key={bodyDiff}>
                      {row[`difficulty_level_${bodyDiff + 1}`] === 0 ||
                      row[`difficulty_level_${bodyDiff + 1}`] === "0"
                        ? "-"
                        : row[`difficulty_level_${bodyDiff + 1}`]}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default TableTopicReport;
