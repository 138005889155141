import React, { useEffect } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./UploadQuestions.css";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import { useState } from "react";
import { Redirect } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pdf from "@mikecousins/react-pdf";
import ReactLoading from "react-loading";
import { uploadQuestionFunction } from "../../../api/questions.js";
import { fetchQueFormat } from "../../../api/qbMasterData";
import { REWRITE_URLS } from "../../config/Api";
import { Player } from "@lottiefiles/react-lottie-player";

var toAccept;

function UploadQuestions() {
  const [fileInfo, setFileInfo] = useState({
    fileType: "",
    fileTypeId: "",
    name: "",
    fullDetails: "",
    nameSource: "",
    fullDetailsSource: "",
    pdfFile: [],
  });

  const [fileDetails, setFileDetails] = useState("");
  const [fileType, setFileType] = useState([]);
  const [numOfPages, setNumOfPages] = useState(1);
  const [uploadDone, setUploadDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startUpLoad, setStartUpLoad] = useState(true);

  console.log("fileType", fileType);
  function handleDropdownChange(e) {
    const valueSele = e.target.value;
    console.log("valueSele", valueSele);
    const idSele = e.target.selectedIndex;
    setFileInfo((prevState) => ({
      ...prevState,
      fileType: valueSele,
      fileTypeId: idSele,
    }));
  }
  if (fileInfo.fileType === "Text") {
    toAccept = ".txt";
  }
  if (fileInfo.fileType === "PDF") {
    toAccept = ".pdf";
  }
  if (fileInfo.fileType === "Image") {
    toAccept = ".png, .jpg, .jpeg";
  }
  if (fileInfo.fileType === "HTML") {
    toAccept = ".html, .htm";
  }
  if (fileInfo.fileType === "PPT") {
    toAccept = ".ppt, .pptx";
  }

  const handelChangeFile = async (e, tab) => {
    const file = e.target.files[0];
    console.log("file", file);
    if (file) {
      if (file.size > 10e8) {
        toast.error("File should be less than 100Mb");
      } else {
        if (tab === "file") {
          const objectUrl = URL.createObjectURL(file);
          setFileDetails(objectUrl);
          setFileInfo((prevState) => ({
            ...prevState,
            name: file.name,
            fullDetails: file,
          }));
        } else if (tab === "source") {
          setFileInfo((prevState) => ({
            ...prevState,
            nameSource: file.name,
            fullDetailsSource: file,
          }));
        }
      }
    } else {
      if (tab === "file") {
        setFileDetails("");
        setFileInfo((prevState) => ({
          ...prevState,
          name: "",
          fullDetails: "",
        }));
      } else if (tab === "source") {
        setFileInfo((prevState) => ({
          ...prevState,
          nameSource: "",
          fullDetailsSource: "",
        }));
      }
    }
  };

  // API to call file type options
  useEffect(() => {
    setStartUpLoad(true);
    const fetchResults = async () => {
      try {
        const getFileType = await fetchQueFormat();
        setFileType(getFileType?.data?.data);
        setStartUpLoad(false);
      } catch (err) {
        // console.log(err.number);
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    };
    fetchResults();
    // eslint-disable-next-line
  }, []);

  function pdfsPage(pdfdata) {
    let numPages = pdfdata._pdfInfo.numPages;
    setNumOfPages(numPages);
  }

  function pdfLoadFail() {
    toast.error("This is an in-valid PDF");
    setFileInfo({ ...fileInfo, fullDetails: "" });
  }
  console.log(fileInfo, "fileInfo");

  async function uploadFile(e) {
    e.preventDefault();
    if (fileInfo.fullDetails === "") {
      toast.error("Choose the Question File");
    }
    if (fileInfo.fullDetailsSource === "") {
      toast.error("Choose the  Source File");
    }
    if (fileInfo.fullDetails !== "" && fileInfo.fullDetailsSource !== "") {
      setLoading(true);
      let userId = window.localStorage.getItem("userId");
      window.localStorage.setItem("fileName", fileInfo.name);
      window.localStorage.setItem("fileType", fileInfo.fileType);
      const fileFullDetails = fileInfo.fullDetails;
      const fileFullDetailsSource = fileInfo.fullDetailsSource;
      let formdata = new FormData();
      formdata.append("question_file_path", fileFullDetails);
      formdata.append("source_file_path", fileFullDetailsSource);
      await uploadQuestionFunction(fileInfo.fileTypeId, userId, formdata)
        .then((response) => {
          toast.success("Upload Successful");
          console.log("upload response", response);
          window.localStorage.setItem(
            "uploadedFileData",
            JSON.stringify(response?.data?.data)
          );
          setLoading(false);
          setUploadDone(true);
        })
        .catch((err) => {
          toast.error("Something Went Wrong");
          console.log("err in uploading question file", err);
          if (err?.response?.data) {
            console.log(
              "err in uploading question file",
              err?.response?.data?.message
            );
            toast.error(err.response.data.message);
          } else {
            toast.error("Server Error");
          }
          setLoading(false);
          setUploadDone(false);
        });
    }
  }

  if (uploadDone) {
    window.localStorage.setItem("previousPath", "uploadQuestions");
    return (
      <Redirect
        to={
          fileInfo.fullDetails !== ""
            ? "/admin/backlog"
            : "/admin/upload-questions"
        }
      ></Redirect>
    );
  }

  return (
    <div>
      {startUpLoad ? (
        <div className="loadingBackground">
          <ReactLoading type="spin" color="#68dff0" className="startLoadSpin" />
        </div>
      ) : null}
      <div className="queContainer">
        <div>
          <h3 className="queH3">
            {" "}
            <ArrowForwardIosIcon className="queH3Arrow" />
            Upload Question File
          </h3>
        </div>
        <form onSubmit={(e) => uploadFile(e)}>
          <div className="queSubContainer">
            <div className="dropdownDivQue">
              <label className="dropdownLabelQue" name="File Type">
                File Type
              </label>
              <select
                className="dropdownSelectQue"
                onChange={(e) => handleDropdownChange(e, "fileType")}
              >
                <option>Select</option>
                {fileType.map((options, keyFileType) => {
                  return (
                    <option key={keyFileType} value={options.display_name}>
                      {options.display_name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="fileUploadQue">
              <h4 className="uploadH4">
                <TextSnippetOutlinedIcon className="uploadPDFIcon" />
                Question File
              </h4>
              <input
                type="file"
                name="question_file_path"
                id="question_file_path"
                className={
                  fileInfo.fileType ? "fileChooseQueNot" : "fileChooseQue"
                }
                onChange={(e) => handelChangeFile(e, "file")}
                accept={toAccept}
                disabled={fileInfo.fileType === "" ? true : false}
              />
            </div>
            {fileInfo.fileType === "PDF" ? (
              <div className="thumbnailContainerUpload">
                {fileInfo.fullDetails !== "" ? (
                  REWRITE_URLS === "1" ? (
                    <object
                      data={fileDetails}
                      type="application/pdf"
                      width="300px"
                      height="250px"
                    >
                      <p>
                        PDF view
                        <a href={fileDetails}>to the PDF!</a>
                      </p>
                    </object>
                  ) : (
                    <Pdf
                      file={fileDetails}
                      page={1}
                      onDocumentLoadSuccess={pdfsPage}
                      onDocumentLoadFail={pdfLoadFail}
                    />
                  )
                ) : null}
                {fileInfo.fullDetails !== ""
                  ? Array.apply(null, {
                      length: numOfPages - 1,
                    }).map((e, keyNum) => (
                      <Pdf file={fileDetails} page={keyNum + 2} />
                    ))
                  : null}
              </div>
            ) : null}
            {fileInfo.fileType === "Image" ? (
              <div className="thumbnailContainerUpload">
                {fileInfo.fullDetails !== "" ? (
                  <img
                    src={fileDetails}
                    alt="Thumbnail"
                    className="imgUploadThumbnail"
                  />
                ) : null}
              </div>
            ) : null}
            {fileInfo.fileType === "Text" ? (
              <div className="thumbnailContainerUpload">
                {fileInfo.fullDetails !== "" ? (
                  <iframe
                    title="TextFrame"
                    src={fileDetails}
                    className="uploadFileText"
                  ></iframe>
                ) : null}
              </div>
            ) : null}
            {fileInfo.fileType === "Animated SVG" ? (
              <div className="thumbnailContainerUpload">
                {fileInfo.fullDetails !== "" ? (
                  <Player
                    autoplay
                    loop
                    src={fileDetails}
                    style={{ width: 150, height: 150, margin: 0 }}
                  />
                ) : null}
              </div>
            ) : null}
            {fileInfo.fileType === "WEBM" ? (
              <div className="thumbnailContainerUpload">
                {fileInfo.fullDetails !== "" ? (
                  <video controls name="media">
                    <source src={fileDetails} type="video/webm" />
                  </video>
                ) : null}
              </div>
            ) : null}
            {fileInfo.fileType === "HTML" ? (
              <div className="thumbnailContainerUpload">
                {fileInfo.fullDetails !== "" ? (
                  <iframe src={fileDetails} title="HTMLFrame"></iframe>
                ) : null}
              </div>
            ) : null}
            <div className="fileUploadQue">
              <h4 className="uploadH4">
                <TextSnippetOutlinedIcon className="uploadPDFIcon" />
                Question Source File
              </h4>
              <input
                type="file"
                name="source_file_path"
                id="source_file_path"
                disabled={fileInfo.fileType === "" ? true : false}
                className={
                  fileInfo.fileType === ""
                    ? "fileChooseQueNot"
                    : "fileChooseQue"
                }
                onChange={(e) => handelChangeFile(e, "source")}
              ></input>
            </div>
          </div>
          <div className="addContainer">
            <button type="submit" className="addSubmit">
              Upload
            </button>
            {loading ? (
              <div className="loadingDiv">
                <ReactLoading
                  type="spinningBubbles"
                  color="#68dff0"
                  className="reactLoadingIcon"
                />
                <h5 className="loadingText">Uploading</h5>
              </div>
            ) : null}
          </div>
        </form>
      </div>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default UploadQuestions;
